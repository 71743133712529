import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import ContactForm from "src/components/ContactForm";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	Flex,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as Icon from "react-icons/bi";
import * as Feather from "react-icons/fi";
import BannerOne from "src/components/about/BannerOne";

const getData = graphql`
	{
		file(name: { eq: "clare-headshot" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 400
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		one: file(name: { eq: "cgw_0001-2" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 616
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		two: file(name: { eq: "Untitled design" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 616
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		four: file(name: { eq: "cgw_0003-4" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 616
					quality: 90
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		three: file(name: { eq: "cgw_0003" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 616
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
		logo: file(name: { eq: "Kingborough-Thrives-stack" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 220
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
	}
`;

const About = () => {
	const data = useStaticQuery(getData);
	const clare = getImage(data.file);
	const logo = getImage(data.logo);
	const one = getImage(data.one);
	const two = getImage(data.two);
	const three = getImage(data.three);
	const four = getImage(data.four);
	return (
		<Layout>
			<HeadSEO
				title="About Clare Glade-Wright | Kingborough Thrives"
				description="I grew up in Kingborough and I am determined to represent the community's views and values."
				slug="/about/"
			/>
			<Box bg="gray.50" py="2rem" overflow="hidden">
				<Flex mx="auto" w="87.5vw" maxW="80rem" justify="center">
					<Box
						as={Link}
						to="/"
						alignSelf="center"
						maxH="100%"
						mr={["1rem", "1rem", "2rem"]}
					>
						<GatsbyImage image={logo} alt="Kingborough Thrives logo" />
					</Box>
					<VStack
						pl="2rem"
						align="flex-start"
						as="nav"
						alignSelf="center"
						spacing="1rem"
						// display={["none", "none", "flex"]}
					>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							href="/"
							_hover={{ bg: "blue.800" }}
						>
							Home
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.700"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							href="/about/"
							_hover={{ bg: "blue.800" }}
						>
							About Clare
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/kingborough-thrives/"
							_hover={{ bg: "blue.800" }}
						>
							Kingborough Thrives
						</Heading>
						<Heading
							size={["xs", "sm", "md"]}
							bg="blue.600"
							color="white"
							p="1rem"
							borderRadius="lg"
							as={Link}
							to="/contact/"
							_hover={{ bg: "blue.800" }}
						>
							Get in Touch
						</Heading>
					</VStack>
				</Flex>
			</Box>
			<Box bg="blue.600" color="white">
				<Box mx="auto" w="87.5vw" maxW="80rem" py="4rem">
					<Heading as="h1">About Clare</Heading>
				</Box>
			</Box>
			<Box id="aboutclare" mx="auto" w="87.5vw" maxW="80rem" py="4rem">
				{/* <VStack spacing="2rem" align="flex-start"> */}
				<Grid gridTemplateColumns={[null, null, "1fr 1fr"]} gap="3rem">
					<Box alignSelf="center" order={[null, null, 1]}>
						<GatsbyImage image={one} alt="Clare Glade-Wright" />
					</Box>

					<VStack
						alignSelf="center"
						order={[null, null, 2]}
						spacing="2rem"
						align="flex-start"
					>
						<Heading>A Bit About Me</Heading>
						<Text>
							I have 2 beautiful children who I tell most days how lucky we are
							to live in this part of the world. I grew up in Kingborough and I
							am determined to represent the community's views and values. I own
							and run a multi-award-winning eco-tourism business where I can
							proudly showcase our beautiful municipality to visitors. I am a
							natural born leader having worked in management and cross
							continental expeditions. I have a passion for gardening and work
							part time in the local garden centre.
						</Text>
					</VStack>
					<Box alignSelf="center" order={[null, null, 4]}>
						<GatsbyImage image={three} alt="Clare Glade-Wright" />
					</Box>
					<VStack
						alignSelf="center"
						order={[null, null, 3]}
						spacing="2rem"
						align="flex-start"
					>
						<Heading as="h3" size="lg">
							My Community Work
						</Heading>
						<Text>
							For the past 2 years I have led the community group{" "}
							<i>Sustainable Living in Kingborough</i> – we’ve run a wide range
							of events; film screenings, plant giveaways, and fundraisers. I am
							the fundraising coordinator for{" "}
							<i>Kingborough Community Missions</i> who work tirelessly
							supplying meals to the disadvantaged people in our region. In the
							last 2 years I petitioned the council to consider FOGO and for
							more Park and Ride facilities which have now been approved. I have
							convened a network of independent candidates, called
							<i>Kingborough Thrives</i>, who will run in the coming general
							council election in October 2022.
						</Text>
					</VStack>
					<Box alignSelf="center" order={[null, null, 5]}>
						<GatsbyImage image={four} alt="Clare Glade-Wright" />
					</Box>
					<VStack
						alignSelf="center"
						order={[null, null, 6]}
						spacing="2rem"
						align="flex-start"
					>
						<Heading as="h3" size="lg">
							My Transferrable Skills for the Role of Mayor
						</Heading>
						<Text>
							My university degrees are Bachelor of Arts and Law. I am an
							esteemed businesswoman who has demonstrated my detail oriented and
							outcome focused energy which has resulted in an award-winning
							business model. I have a diverse background in management and
							leading tourism adventures internationally. This background has
							given me skills which enable me to manage and facilitate teams who
							have differing views, a skill set that will be well utilised as
							Mayor. As Mayor of Kingborough, I will work with my fellow
							councillors, to create cohesion, collaboration and a respectful
							council that represents the values of the community.
						</Text>
					</VStack>
					<Box alignSelf="center" order={[null, null, 8]}>
						<GatsbyImage image={two} alt="Clare Glade-Wright" />
					</Box>
					<VStack
						alignSelf="center"
						order={[null, null, 7]}
						spacing="2rem"
						align="flex-start"
					>
						<Heading as="h3" size="lg">
							Can you elect a Mayor without Prior Experience?
						</Heading>
						<Text>
							It’s important to recognise that the Mayor does not hold any more
							power than any other councillor; the Mayor contributes just 1 vote
							on each decision bought to a council. Deputy Mayor Jo Westwood is
							a shining example of being voted straight in and doing a wonderful
							job in both the capacity of Deputy and also Acting Mayor when
							required. I have been a dedicated attendee of council meetings
							over the last 2 years and I have the required skills for the role.
						</Text>
						<Text>
							Much-loved former Kingborough Mayor and long-serving Councillor,
							Dr Graham Bury has come out in support of Clare's election.
						</Text>
						<Text>
							"Clare Glade-Wright would make an excellent member of Kingborough
							Council and her campaign has my full support. She has training in
							law and is currently a successful small business owner in the
							municipality. I know that she works cooperatively and is already
							involved in local community initiatives," he said.
						</Text>
					</VStack>
				</Grid>
				{/* </VStack> */}
			</Box>
		</Layout>
	);
};

export default About;
